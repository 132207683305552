
import { IonPage, IonContent, alertController, toastController, loadingController, IonButton, IonSelect, IonSelectOption, IonTextarea, IonSpinner, IonHeader, IonTitle, IonToolbar, IonBackButton } from '@ionic/vue';
import { defineComponent, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
/**
 * services
 */
import services from "../../../services";
import { Analytics } from '../../../common/analytics';

export default defineComponent({
  components: { IonPage, IonContent, IonButton, IonSelect, IonSelectOption, IonTextarea, IonSpinner, IonHeader, IonTitle, IonToolbar, IonBackButton },
  setup(){
    const store = useStore();
    const router = useRouter();
    const screenName = "PAGE-SETTING_WITHDRAWAL";
    const analytics = new Analytics;
    const state = reactive({
      form:{
          content:'',
          type:''
        },
        user: {
          loading: true,
          data: {}
        },
        contentLoading: true
    });

    const presentAlert = async (message: any, callback: any = null) => {
        const alert = await alertController
          .create({
            header: "알림",
            backdropDismiss: false,
            message: message,
            buttons: [
              {
                text: '확인',
                handler: () => {
                  if ( callback ) {
                    callback();
                  }
                },
              },
            ],
          });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
      };

    const openToast = async (message: any) => {
      const toast = await toastController
        .create({
          message: message,
          duration: 2000
        })
      return toast.present();
    };

    const fetch = async () => {
      await services.authUser().then(
         response => {
          const { data } = response;
          state.user.data = data;
          state.user.loading = false;
         },
          error => {
            let message = '';
            try {
              message = error.response.data.message;
            } catch (e) {
              message = '';
              console.log(e);
            }
            console.log(message);
          console.log(message);
          openToast(message)
        }
      );

      setTimeout(()=>{
        state.contentLoading = false
      } ,500)
    }

    const onSubmit = async () => {
      const loading = await loadingController
      .create({
          cssClass: 'my-custom-class',
          message: '탈퇴 중입니다.',
      });
      await loading.present();
      const params = {
        content: state.form.content,
        type: state.form.type
      }
      await services.authWithdrawal(params).then(
        response => {
          if(response.status == 204) {
              setTimeout(function() {
                loading.dismiss()
                store
                  .dispatch("logout")
                  .then(response => {
                    console.log(response);
                    if (response.status == 204) {
                      setTimeout(function() {
                        loading.dismiss()
                        router.push('/')
                      }, 1000);
                    } else {
                      setTimeout(function() {
                        loading.dismiss()
                      }, 1000);
                    }
                  })
                  .catch(message => {
                    console.log(message);
                    setTimeout(function() {
                      loading.dismiss()
                      presentAlert(message);
                    }, 1000);
                  });
            }, 1000);
          } else {
            setTimeout(function() {
              loading.dismiss()
            }, 1000);
          }
        },
        error => {
          let message = '';
          try {
            message = error.response.data.message;
          } catch (e) {
            message = '';
            console.log(e);
          }
          console.log(message);
          setTimeout(function() {
            loading.dismiss()
            presentAlert(message);
          }, 1000);
        }
      )
    }

    onMounted(() => {
      fetch()
      console.log('onMounted');
      analytics.setScreenName(screenName);
    });
    return { state, onSubmit }

  }

})
